<!--
 * @Description: 
-->
<!--
 * @Description: 邀新活动活动规则
-->
<template>
	<div class="rules">
		<div class="item ">
            <div style="text-align: center;color: #f5525c;font-size: 12px;font-weight: 600;margin-bottom: 5px;">被邀请用户请在30天内完成下单，否则积分发放失败</div>
			<p class="title">一、活动开始时间</p>
			<p class="memo">永久有效</p>
		</div>
		<div class="item ">
			<p class="title">二、活动期间</p>
			<p class="memo">
				1、推荐人（已注册小易多多）推荐的新用户（从未在小易多多注册）在小易多多APP注册、登陆并下单成功，可获得积分奖励；
			</p>
			<p class="memo">
				2、<b>被推荐人(即新用户)</b>首次购买社保、心理测评任一产品，推荐人可获得额外积分奖励（具体分值详见下表）；
			</p>
			<p class="memo">
				3、<b>被推荐人(即新用户)</b>只要购买商保产品、体检产品（不仅限首次），推荐人即可获得额外积分奖励；
			</p>
            <div class="img">
                <img src="@/assets/img/rules3.png" alt="">
            </div>
            <div class="memoDiv">
                <span style="color:#FA6463;">注：</span>新用户成功注册并下单后，积分奖励实时发放至推荐人账户，<b>待积分生效后可用于积分抽奖，健康商城兑换各种好礼；</b>新用户 购买同个产品的，推荐人仅可获赠一次积分。
                <br>
                <span style="color:#FA6463;">*</span>京东安联少儿白血病保障计划、瑞华个人短期重疾险、个人账户安全险、京东安联“畅想神州”境内旅行保障计划、自选型交通工具意外险等险种不参与本次活动。积分获取无上限，邀请新用户越多，则可获取积分值越高。
            </div>
		</div>

        <div class="item mb26">
			      <p class="title">三、活动玩法</p>
            <p class="memo">1、推荐人分享活动页面到微信/朋友圈，好友进入活动页面后，输入手机号进行关联；关联成功后登录小易多多APP，且被推荐人下单成功，推荐人即可获得积分奖励；好友在小易多多平台购买任意产品，推荐人可再获额外奖励。</p>
            <p style="font-size:12px;color:#666666;"><span style="color:#FA6463;">*</span>如你的好友未出现在邀请记录内，则表示未关联成功或关联失败</p>
            <p class="memo">2、可同时推荐多人参与，推荐人数不设上限。</p>
            <p class="memo">3、本活动最终解释权归小易多多APP所有。</p>
            <!-- <div class="img">
                <img src="@/assets/img/rules1.png" alt="">
            </div> -->
        </div>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
    mounted() {
		window.document.title = '邀请规则'
	}
}
</script>

<style lang="scss" scoped>
.rules{
    box-sizing: border-box;
    padding: 0 6.6vw;
    .item{
        margin-top: 7vw;
        .title{
            font-size: 4.5vw;
            color:#434343;
            font-weight: bold;
            margin-bottom: 3.4vw;
        }
        .memo{
            font-size: 3.5vw;
            color:#666666;
            line-height: 6vw;
            b{
                color:#4C4C4C;
            }
        }
        .img{
            text-align: center;
            overflow: hidden;
            img{
                width: 100%;
                margin-top: 5vw;
                margin-bottom: 6vw;
            }
        }
        .memoDiv{
            font-size: 3vw;
            color:#666666;
            line-height: 5vw;
        }   
    }
    .mb26{
      margin-bottom: 3vw;
    }

}
</style>
